import { Auth } from 'aws-amplify'
import React, { useState } from 'react'
import Form from 'react-bootstrap/Form'
import { useHistory } from 'react-router-dom'
import LoaderButton from '../components/LoaderButton'
import { useAppContext } from '../lib/contextLib'
import { useFormFields } from '../lib/hooksLib'
import { onError } from '../lib/errorLib'
import './Signup.css'

export default function Signup() {
    const [fields, handleFieldChange] = useFormFields({
        email: '',
        password: '',
        confirmPassword: '',
        confirmationCode: ''
    })
    const history = useHistory()
    const [newUser, setNewUser] = useState(null)
    const { userHasAuthenticated } = useAppContext()
    const [isLoading, setIsLoading] = useState(false)

    function validateForm() {
        return (
            fields.email.length > 0 &&
            fields.password.length > 0 &&
            fields.password === fields.confirmPassword
        )
    }

    function validateConfirmationForm() {
        return fields.confirmationCode.length > 0
    }

    async function handleSubmit(event) {
        event.preventDefault()

        setIsLoading(true)

        try {
            const newUser = await Auth.signUp({
                username: fields.email,
                password: fields.password
            })
            setIsLoading(false)
            setNewUser(newUser)
        } catch (e) {
            onError(e)
            /**
             * TODO: handle trying ot signup a username which already exists
             *
             * 
            if (e.code && e.code === 'UsernameExistsException') {
                // setNewUser({
                //     username: fields.email,
                //     password: fields.password
                // })
                try {
                    await Auth.resendSignUp(fields.email)
                } catch (e2) {
                    onError(e2)
                    if (e2.code && e2.code.includes('User is already confirmed')) {
                        alert(`User already exists. Please log in with your current password.`)
                        history.push('/login')   // All of it works up to here, but it doesn't redirect to /login
                    }
                }                
            }
             */
            setIsLoading(false)
        }
    }

    async function handleConfirmationSubmit(event) {
        event.preventDefault()

        setIsLoading(true)

        try {
            await Auth.confirmSignUp(fields.email, fields.confirmationCode)
            await Auth.signIn(fields.email, fields.password)

            userHasAuthenticated(true)
            history.push('/')
        } catch (e) {
            onError(e)
            setIsLoading(false)
        }
    }

    function renderConfirmationForm() {
        return (
            <Form onSubmit={handleConfirmationSubmit}>
                <Form.Group controlId='confirmationCode' size='lg'>
                    <Form.Label>Confirmation Code</Form.Label>
                    <Form.Control
                        autoFocus
                        type='tel'
                        onChange={handleFieldChange}
                        value={fields.confirmationCode}
                    />
                    <Form.Text muted>Please check your email for the code.</Form.Text>
                </Form.Group>
                <LoaderButton
                    block
                    size='lg'
                    type='submit'
                    variant='success'
                    isLoading={isLoading}
                    disabled={!validateConfirmationForm()}
                >
                    Verify
                </LoaderButton>
            </Form>
        )
    }

    function renderForm() {
        return (
            <Form onSubmit={handleSubmit}>
                <Form.Group controlId='email' size='lg'>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                        autoFocus
                        type='email'
                        value={fields.email}
                        onChange={handleFieldChange}
                    />
                </Form.Group>
                <Form.Group controlId='password' size='lg'>
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                        type='password'
                        value={fields.password}
                        onChange={handleFieldChange}
                    />
                </Form.Group>
                <Form.Group controlId='confirmPassword' size='lg'>
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control
                        type='password'
                        value={fields.confirmPassword}
                        onChange={handleFieldChange}
                    />
                </Form.Group>
                <LoaderButton
                    block
                    size='lg'
                    type='submit'
                    variant='success'
                    isLoading={isLoading}
                    disabled={!validateForm()}
                >
                    Signup
                </LoaderButton>
            </Form>
        )
    }

    return (
        <div className='Signup'>
            {newUser === null ? renderForm() : renderConfirmationForm()}
        </div>
    )
}